export default {
    data(){
      let self = this
      var time = setInterval(()=>{
        self.num--
        if(self.num<1){
          clearInterval(time)
          self.pathUrl?self.$router.push({path:self.pathUrl,params:{refresh:1}})
          :self.$router.push({name:self.path,params:{refresh:1}})
        }
      },1000)
      return{
        orderNo:'',
        num:10,
        path:'',
        pathUrl:'',
        setTime:time,
      }
    },
    methods:{
       
    },
    watch:{
       "$route"(val){
          clearInterval(this.setTime)
       }
    },
    created(){
       this.path = this.$route.query.path
       this.pathUrl = this.$route.query.pathUrl
    },
 }